// ParentComponent.js
import React, { useState } from "react";
import Card from "./Card";
import Pagination from "./Pagination";

const ParentComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const mockData = [
    {
      id: 1,
      img: "/assets/partner3.png",
      company: "Some group",
      status: "Expert",
      stars: 4,
      descp: "Lorem Ipsum is simply dummy text of. Lorem Ipsum has bee...",
      service: "shinanyut",
      serviceCount: "3 services",
    },
    {
      id: 2,
      img: "/assets/partner1.png",
      company: "Clean group",
      status: "Expert",
      stars: 3,
      descp: "Lorem Ipsum is simply dummy text of. Lorem Ipsum has bee...",
      service: "shinanyut",
      serviceCount: "3 services",
    },
    {
      id: 3,
      img: "/assets/partner3.png",
      company: "Language center",
      status: "Expert",
      stars: 3,
      descp: "Lorem Ipsum is simply dummy text of. Lorem Ipsum has bee...",
      service: "shinanyut",
      serviceCount: "3 services",
    },
    {
      id: 4,
      img: "/assets/partner1.png",
      company: "Clean group",
      status: "Expert",
      stars: 2,
      descp: "Lorem Ipsum is simply dummy text of. Lorem Ipsum has bee...",
      service: "shinanyut",
      serviceCount: "3 services",
    },
    {
      id: 5,
      img: "/assets/partner1.png",
      company: "Multi group",
      status: "Expert",
      stars: 2,
      descp: "Lorem Ipsum is simply dummy text of. Lorem Ipsum has bee...",
      service: "shinanyut",
      serviceCount: "3 services",
    },
    {
      id: 6,
      img: "/assets/multi.png",
      company: "Multi group",
      status: "Expert",
      stars: 2,
      descp: "Lorem Ipsum is simply dummy text of. Lorem Ipsum has bee...",
      service: "shinanyut",
      serviceCount: "3 services",
    },
    {
      id: 7,
      img: "/assets/multi.png",
      company: "Multi group",
      status: "Expert",
      stars: 2,
      descp: "Lorem Ipsum is simply dummy text of. Lorem Ipsum has bee...",
      service: "shinanyut",
      serviceCount: "3 services",
    },
    {
      id: 8,
      img: "/assets/multi.png",
      company: "Multi group",
      status: "Expert",
      stars: 1,
      descp: "Lorem Ipsum is simply dummy text of. Lorem Ipsum has bee...",
      service: "shinanyut",
      serviceCount: "3 services",
    },
    {
      id: 9,
      img: "/assets/multi.png",
      company: "Multi group",
      status: "Expert",
      stars: 1,
      descp: "Lorem Ipsum is simply dummy text of. Lorem Ipsum has bee...",
      service: "shinanyut",
      serviceCount: "3 services",
    },
  ];

  const itemsPerPage = 3; // Number of items per page
  const totalItems = mockData.length; // Total number of items
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const paginateData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return mockData.slice(startIndex, endIndex);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <Card data={paginateData()} />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default ParentComponent;
